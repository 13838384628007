import * as React from 'react';
// material
import { Typography } from '@mui/material';
// layout
import { MainLayout } from 'layouts';
// components
import { ContentBox } from 'components';
// hooks
import { useLocales } from 'hooks';

const NotFoundPage = () => {
  const { translate: t } = useLocales();
  return (
    <MainLayout>
      <ContentBox background="paper" sx={{ textAlign: 'center' }}>
        <Typography variant="h1" gutterBottom>
          {t('pages.notFound.title')}
        </Typography>
        <Typography>{t('pages.notFound.description')}</Typography>
      </ContentBox>
    </MainLayout>
  );
};

export default NotFoundPage;
